<template>
	<EditorForm @submit="createEditor" @cancel="gotoList" />
</template>

<script>
import { createNamespacedHelpers } from "vuex"
import { RESET_STATE_PAYSHEET } from "../../paysheet/store/mutation-types"
import { CREATE_EDITOR } from "../store/action-types"
import { RESET_STATE_EDITOR } from "../store/mutation-types"
const { mapActions, mapMutations } = createNamespacedHelpers("editors")
const { mapMutations: paysheetMutations } = createNamespacedHelpers("paysheet")

export default {
	name: "CreateEditor",
	components: {
		EditorForm: () => import("../componetns/EditorForm"),
	},
	beforeDestroy() {
		this.RESET_STATE_EDITOR()
		this.RESET_STATE_PAYSHEET()
	},
	methods: {
		...mapActions({ CREATE_EDITOR }),
		...mapMutations({ RESET_STATE_EDITOR }),
		...paysheetMutations({ RESET_STATE_PAYSHEET }),
		gotoList() {
			this.$router.go(-1)
		},
		async createEditor(params) {
			await this.CREATE_EDITOR(params)
		},
	},
}
</script>

<style></style>
